var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "green-border-box in-padd white-bg" }, [
    _vm.$store.state.userData.couponFeature == 1
      ? _c("div", [
          _c(
            "div",
            [
              _c(
                "div",
                { staticClass: "row" },
                _vm._l(_vm.coupons, function(coupon) {
                  return _c(
                    "div",
                    {
                      key: coupon.id,
                      staticClass: "col-xs-12 col-sm-6 item-list"
                    },
                    [
                      _c("CouponItem", {
                        attrs: { coupon: coupon },
                        on: {
                          delete: function($event) {
                            _vm.actionCoupon = coupon
                          }
                        }
                      })
                    ],
                    1
                  )
                }),
                0
              ),
              _c("div", { staticClass: "text-center" }, [
                _vm.hasLoadMore
                  ? _c(
                      "button",
                      {
                        staticClass: "btn-positive",
                        attrs: { disabled: _vm.busy },
                        on: { click: _vm.loadMore }
                      },
                      [_vm._v("Load more")]
                    )
                  : _vm._e()
              ]),
              !_vm.busy && (!_vm.coupons || _vm.coupons.length == 0)
                ? _c("h3", { staticClass: "text-center mt20" }, [
                    _vm._v("\n          No Coupon found.\n        ")
                  ])
                : _vm._e(),
              _c("DeleteCouponPopup", {
                attrs: {
                  brandId: _vm.brandId,
                  popupId: "delete-coupon-popup",
                  coupon: _vm.actionCoupon
                },
                on: { deleted: _vm.refresh }
              })
            ],
            1
          )
        ])
      : _c("h2", { staticStyle: { "text-align": "center" } }, [
          _vm._v("Coupon Feature is not available for you!")
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }